import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthProvider } from "./contexts/AuthContext";
import PermissionsProvider from "./contexts/PermissionsContext";
import { DialogProvider } from "./contexts/DialogContext";
import { Toaster } from "react-hot-toast";

import { msalConfig } from "./authConfig/authConfig";

import { LoadingBarProvider } from "./contexts/LoadingBarContext";
import { ModalPreviewProvider } from "./contexts/ModalPreviewContext";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingBarProvider>
    <MsalProvider instance={msalInstance}>
      <PermissionsProvider>
        <AuthProvider>
          <BrowserRouter>
            <DialogProvider>
              <ModalPreviewProvider>
                <App />
              </ModalPreviewProvider>
            </DialogProvider>
          </BrowserRouter>
        </AuthProvider>
      </PermissionsProvider>
      <Toaster />
    </MsalProvider>
  </LoadingBarProvider>
);
