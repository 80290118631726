import React, { useState, useEffect, useRef, Children } from "react";
import cn from "classnames";
import Switch from "../Switch";
import styles from "./PromptBox.module.sass";
import { toast } from "react-hot-toast";
import PromptInput from "./PromptInput";
import ImageUpload from "./ImageUpload";
import { CheckIsUserLimited, updateGenerationAttempts } from "./rateLimit";
import Dropdown from "../Dropdown";
import Image from "../Image";
import { useNavigate, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import popupStyles from "../Popup/Popup.module.sass";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";
import stylesTooltip from "../CustomTooltip/CustomTooltip.module.sass";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useAuth } from "../../contexts/AuthContext";
import ColorPicker from "../ColorPicker/index";

const MAX_IMAGE_DIMENSION = 1344;
const PROMPT_MIN_LENGTH = 3;

const ReferencesOptions = [
  "No Reference",
  "Image Variations",
  "Detailed Sketch",
  "Concept Sketch",
  "Artline Sketch",
  "Pose Reference",
];

const OptimalRangeValue = {
  "Image Variations": 71,
  "Detailed Sketch": 61,
  "Concept Sketch": 71,
  "Artline Sketch": 61,
};

const PromptBox = ({
  artifact,
  className,
  OnGenerateClicked,
  canGenerate,
  placeholder,
  forcedCharacterSuggestions,
  forcedItemsSuggestions,
  isCharacter,
}) => {
  const { artifacts } = useAuth();
  const [visible, setVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [numImagesPerPrompt, setNumImagesPerPrompt] = useState(4);
  const [isRemoveBackground, setIsRemoveBackground] = useState(false);
  const [enrichPromptByChatGPTFlag, setEnrichPromptByChatGPTFlag] =
    useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [promptBoxSettings, setPromptBoxSettings] = useState({});

  const filteredReferencesOptions =
    artifact?.type !== "character"
      ? ReferencesOptions.filter((option) => option !== "Pose Reference")
      : ReferencesOptions;

  const [selectedBasedOnArtifact, setSelectedBasedOnArtifact] = useState(
    artifacts[0]
  );
  const [basedOnArtifactOptions, setBasedOnArtifactOptions] = useState([]);

  useEffect(() => {
    if (artifact) {
      setPromptBoxSettings(artifact.prompt_box_settings);
    }
  }, [artifact]);

  useEffect(() => {
    if (artifacts) {
      let options = artifacts
        .filter((artifact) =>
          ["scene", "character", "item", "background"].includes(artifact.type)
        )
        .map((artifact) => artifact.display_name);
      setBasedOnArtifactOptions(options);
      if (options.length > 0) {
        setSelectedBasedOnArtifact(options[0]);
      }
    }
  }, [artifacts]);

  const [selectedReferenceOption, setSelectedReferenceOption] = useState(
    ReferencesOptions[0]
  );

  const [DimensionsOptions, setDimensionsOptions] = useState([
    "1:1",
    "13:19",
    "19:13",
    "7:9",
    "9:7",
    "4:7",
    "7:4",
    "12:5",
  ]);
  const [selectedDimensionsOption, setSelectedDimensionsOption] = useState();

  const location = useLocation();
  if (location.pathname === "/" && !selectedDimensionsOption) {
    setSelectedDimensionsOption("1:1");
  }
  const [charactersSuggestions, setCharactersSuggestions] = useState([]);
  const [itemsSuggestions, setItemsSuggestions] = useState([]);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const inputRef = useRef(null);

  const [isRangeVisible, setIsRangeVisible] = useState(false);
  const [rangeValue, setRangeValue] = useState();
  const sliderRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const [masterColor, setMasterColor] = useState("");

  const toggleRangeVisibility = () => {
    setIsRangeVisible(!isRangeVisible);
  };

  const handleRangeChange = (event) => {
    const value = event.target.value;
    setRangeValue(value);
    updateSliderBackground(event.target, value);
  };

  const updateSliderBackground = (slider, value) => {
    slider.style.background = `linear-gradient(to right, #823bf5 0%, #823bf5 ${value}%, #ddd ${value}%, #ddd 100%)`;
  };

  useEffect(() => {
    if (sliderRef.current) {
      updateSliderBackground(sliderRef.current, rangeValue);
    }
  }, [rangeValue, isRangeVisible]);

  useEffect(() => {
    if (selectedReferenceOption === ReferencesOptions[0]) {
      setImagePreview(null);
      setSelectedImage(null);
    }
  }, [selectedReferenceOption]);

  useEffect(() => {
    if (artifact) {
      setItemsSuggestions(
        Object.keys(artifact.suggestions).map((key) => ({
          id: key,
          display: key,
          value: artifact.suggestions[key],
        }))
      );
    } else {
      if (forcedCharacterSuggestions) {
        setCharactersSuggestions(
          Object.keys(forcedCharacterSuggestions).map((key) => ({
            id: key,
            display: key,
            value: forcedCharacterSuggestions[key],
          }))
        );
      }
      if (forcedItemsSuggestions) {
        setItemsSuggestions(
          Object.keys(forcedItemsSuggestions).map((key) => ({
            id: key,
            display: key,
            value: forcedItemsSuggestions[key],
          }))
        );
      }
    }
  }, [artifact, forcedCharacterSuggestions, forcedItemsSuggestions]);

  const mergeDefaultDimensionsWithGivenDimensions = () => {
    if (
      artifact?.model_parameters?.height &&
      artifact?.model_parameters?.width
    ) {
      const height = artifact.model_parameters.height;
      const width = artifact.model_parameters.width;
      let defaultDimensions;
      if (height < width) {
        defaultDimensions = `${(height / width).toFixed(2)}:1`;
      } else {
        defaultDimensions = `1:${(width / height).toFixed(2)}`;
      }
      if (!DimensionsOptions.includes(defaultDimensions)) {
        setDimensionsOptions([defaultDimensions, ...DimensionsOptions]);
      }
      if (!selectedDimensionsOption)
        setSelectedDimensionsOption(defaultDimensions);
    } else {
      setSelectedDimensionsOption(DimensionsOptions[0]);
    }
  };

  useEffect(() => {
    mergeDefaultDimensionsWithGivenDimensions();
  }, [artifact]);
  useEffect(() => {
    setIsDisabled(!canGenerate);
  }, [canGenerate]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const fetchImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "fetchedImage", { type: blob.type });
      console.log("FETCHED FILE::", file);
      setSelectedImage(file);
    } catch (error) {}
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      validateAndSetImage(file);
    } else {
      setSelectedReferenceOption(ReferencesOptions[0]);
    }
  };

  const validateAndSetImage = (file) => {
    console.log("FILE::", file);
    const fileType = file.type;
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (!validTypes.includes(fileType)) {
      toast.error(
        "Invalid file format. Please upload a PNG, JPG, or JPEG image."
      );
      setSelectedImage(null);
      setImagePreview(null);
      setSelectedReferenceOption(ReferencesOptions[0]);
      return;
    }

    const img = new window.Image();
    img.onload = () => {
      if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
        toast.error(
          "Image dimensions exceed 1344px in one or both directions. Please upload a smaller image."
        );
        setSelectedImage(null);
        setImagePreview(null);
        setSelectedReferenceOption(ReferencesOptions[0]);
      } else {
        console.log("SetSelectedImage: ", file);
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
      }
    };
    img.src = URL.createObjectURL(file);
  };

  const validateInputThenGenerateCreations = () => {
    if (CheckIsUserLimited()) {
      return;
    }
    updateGenerationAttempts();

    const characters = prompt.match(/@\w+/g) || [];
    const items = prompt.match(/#\w+/g) || [];
    if (characters.length + items.length != 1 && location.pathname === "/") {
      toast.error("Only exactly character or item can be used at a time.");
      return;
    }

    let parsedPrompt = prompt;
    const suggestions = charactersSuggestions.concat(itemsSuggestions);
    suggestions.forEach((suggestion) => {
      //it should be @ or # followed by the display name
      const regex = new RegExp(`(@|#)${suggestion.display}(@|#)`, "g");
      parsedPrompt = parsedPrompt.replace(regex, suggestion.value);
    });

    if (parsedPrompt.length < PROMPT_MIN_LENGTH) {
      toast.error(
        `Prompt must be at least ${PROMPT_MIN_LENGTH} characters long.`
      );
      return;
    }

    try {
      if (location.pathname === "/") {
        const promptForRedirection = prompt.replace(/@/g, "").replace(/#/g, "");
        const artifactData =
          charactersSuggestions.find((suggestion) =>
            promptForRedirection.includes(suggestion.display)
          ) ||
          itemsSuggestions.find((suggestion) =>
            promptForRedirection.includes(suggestion.value)
          );
        artifact = artifacts.find(
          (artifact) => artifact.display_name === artifactData.display
        );
      }
      OnGenerateClicked({
        prompt: parsedPrompt,
        numImagesPerPrompt: numImagesPerPrompt,
        isRemoveBackground: isRemoveBackground,
        enrichPromptByChatGPTFlag: enrichPromptByChatGPTFlag,
        image: selectedImage,
        referenceType: selectedReferenceOption,
        dimensionsRatio: selectedDimensionsOption,
        artifact: artifact,
        artifactId: artifact.artifact_id,
        settings: { strength: rangeValue },
        masterColor: masterColor,
      });

      console.log(location.pathname);
      if (location.pathname === "/") {
        toast.success(
          "Generation started successfully! \n Redirecting to the artifact page..."
        );
        //wait 5 seconds before navigating to the artifact page
        setTimeout(() => {
          navigate("/artifacts/" + artifact.artifact_id);
        }, 5000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const clearImage = () => {
    setImagePreview(null);
    setSelectedImage(null);
    setSelectedReferenceOption(ReferencesOptions[0]);
  };

  const handleReferenceOptionChange = (option) => {
    setSelectedReferenceOption(option);
    if (option && option !== ReferencesOptions[0]) {
      fileInputRef.current?.click();
    }
    setRangeValue(OptimalRangeValue[option]);
  };

  return (
    <div className={cn(styles.search, className, { [styles.active]: visible })}>
      <div className={styles.head} style={{ position: "relative" }}>
        <div style={{ position: "relative" }}>
          <PromptInput
            placeholder={placeholder}
            prompt={prompt}
            setPrompt={setPrompt}
            validateInputThenGenerateCreations={
              validateInputThenGenerateCreations
            }
            isDisabled={isDisabled}
            inputRef={inputRef}
            itemsSuggestions={itemsSuggestions}
            charactersSuggestions={charactersSuggestions}
            // style={{ width: '100%' }}
          />
          {promptBoxSettings.master_color !== false && (
            <div
              style={{
                position: "absolute",
                top: "0%",
                right: "3px",
                zIndex: 2,
              }}
            >
              <ColorPicker
                masterColor={masterColor}
                setMasterColor={setMasterColor}
                artifact={artifact}
              />
            </div>
          )}
        </div>

        <div className={styles.buttonsPanel}>
          {promptBoxSettings.reference_type !== false && (
            <Dropdown
              id="ReferencesOptions"
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={selectedReferenceOption}
              setValue={handleReferenceOptionChange}
              options={filteredReferencesOptions}
              style={{ width: "200px" }}
              showToolTip={true}
              showOptionTooltips={true}
            />
          )}

          {promptBoxSettings.dimensions !== false && (
            <Dropdown
              id="DimensionsOptions"
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={selectedDimensionsOption}
              setValue={setSelectedDimensionsOption}
              options={DimensionsOptions}
              style={{ width: "200px", marginLeft: "20px" }}
              showToolTip={true}
            />
          )}

          {promptBoxSettings.improve_prompt !== false && (
            <CustomTooltip title={TooltipData["ImprovePrompt"]} placement="top">
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "190px",
                }}
              >
                Improve Prompt?
                <Switch
                  value={enrichPromptByChatGPTFlag}
                  onChange={() =>
                    setEnrichPromptByChatGPTFlag(!enrichPromptByChatGPTFlag)
                  }
                />
              </div>
            </CustomTooltip>
          )}
          <div
            style={
              promptBoxSettings.improve_prompt === false &&
              promptBoxSettings.reference_type === false &&
              promptBoxSettings.dimensions === false
                ? { position: "relative", left: 740 }
                : {}
            }
          >
            {!isDisabled && selectedReferenceOption != "No Reference" ? (
              <>
                <div className={styles.ArrowAndForgeContainer}>
                  <Popup
                    trigger={
                      <div>
                        <CustomTooltip
                          // id="Settings"
                          title={TooltipData["Settings"]}
                          placement="top"
                        >
                          <button
                            className={styles.rangeButton}
                            onClick={toggleRangeVisibility}
                          >
                            <span className="arrow">&#9660;</span>
                          </button>
                        </CustomTooltip>
                      </div>
                    }
                    position="bottom"
                    contentStyle={{ minWidth: "380px" }}
                    open={isRangeVisible}
                    onClose={() => setIsRangeVisible(false)}
                    onOpen={() => {
                      if (sliderRef.current) {
                        updateSliderBackground(sliderRef.current, rangeValue);
                      }
                    }}
                  >
                    <div className={popupStyles.popupContent}>
                      <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <CustomTooltip
                          title={TooltipData["Strength"]}
                          placement="left"
                          classes={{
                            tooltip:
                              stylesTooltip["customTooltipSettingsTitles"],
                          }}
                        >
                          <HelpOutlineIcon
                            style={{
                              fontSize: "16px",
                              marginBottom: "1.75px",
                              marginRight: "2px",
                              marginLeft: "-3px",
                              visibility: isHovered ? "visible" : "hidden",
                              opacity: isHovered ? 1 : 0,
                              transition:
                                "opacity 0.1s ease-in-out, visibility 0.1s ease-in-out",
                              color: "#5c5c5d",
                            }}
                          />
                        </CustomTooltip>
                        <div
                          className={popupStyles.popupTitle}
                          style={{
                            marginLeft: "3px",
                            textAlign: "center",
                            lineHeight: "1.4",
                          }}
                        >
                          Prompt Strength
                        </div>
                      </div>
                      <input
                        type="range"
                        id="level"
                        ref={sliderRef}
                        class={popupStyles.slider}
                        min="0"
                        max="100"
                        value={rangeValue}
                        onChange={handleRangeChange}
                      />
                      <div className={popupStyles.RangeValueBox}>
                        <div class={popupStyles.RangeValueDis} id="rangeValue">
                          {rangeValue}%
                        </div>
                      </div>
                    </div>
                  </Popup>
                  <CustomTooltip
                    // id="Forge"
                    title={TooltipData["Forge"]}
                    placement="top"
                  >
                    <button
                      onClick={validateInputThenGenerateCreations}
                      className={styles.forgeButton}
                    >
                      <Image
                        className={styles.pic}
                        src="/images/generate-icon-light.png"
                        srcDark="/images/generate-icon-dark.png"
                        alt="Generate"
                      />
                    </button>
                  </CustomTooltip>
                </div>
              </>
            ) : !isDisabled && selectedReferenceOption == "No Reference" ? (
              <CustomTooltip
                // id="Forge"
                title={TooltipData["Forge"]}
                placement="top"
              >
                <button
                  onClick={validateInputThenGenerateCreations}
                  className={styles.forgeButton}
                >
                  <Image
                    className={styles.pic}
                    src="/images/generate-icon-light.png"
                    srcDark="/images/generate-icon-dark.png"
                    alt="Generate"
                  />
                </button>
              </CustomTooltip>
            ) : (
              <button onClick={() => {}} className={styles.cancelButton}>
                Please wait...
              </button>
            )}
          </div>
        </div>

        {artifact?.artifact_required && (
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "50px",
              paddingRight: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <br />
            Based on Artifact
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={selectedBasedOnArtifact}
              setValue={setSelectedBasedOnArtifact}
              options={basedOnArtifactOptions}
            />
          </div>
        )}

        <ImageUpload
          imagePreview={imagePreview}
          handleFileChange={handleFileChange}
          clearImage={clearImage}
          fileInputRef={fileInputRef}
        />
      </div>
    </div>
  );
};

export default PromptBox;
