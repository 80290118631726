import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import CustomTooltip from "../CustomTooltip";
import TooltipData from "../CustomTooltip/TooltipsData";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import stylesTooltip from "../CustomTooltip/CustomTooltip.module.sass";

const Dropdown = ({
  id,
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  small,
  upBody,
  style,
  showToolTip = false,
  showOptionTooltips = false,
}) => {
  const [visible, setVisible] = useState(false);

  // Hover state lifted to track the currently hovered index globally
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleClick = (selectedValue) => {
    setValue(selectedValue);
    setVisible(false);
  };

  const isScrollable = options.length > 5;

  const getFirstWord = (text) => {
    if (typeof text === "string") {
      return text.split(" ")[0]; // Splits by spaces and returns the first word
    } else if (text?.label) {
      return text.label.split(" ")[0];
    }
    return "";
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {/* Add tooltip here if needed */}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
        style={{ ...style }}
      >
        {showToolTip ? (
          <CustomTooltip title={TooltipData[id]} placement="top">
            <div
              className={cn(styles.head, classDropdownHead)}
              onClick={() => setVisible(!visible)}
            >
              <div className={styles.selection}>
                {typeof value === "object" && value.content ? (
                  <div style={{ paddingTop: 14 }}>{value.content}</div>
                ) : (
                  value
                )}
              </div>
            </div>
          </CustomTooltip>
        ) : (
          <div
            className={cn(styles.head, classDropdownHead)}
            onClick={() => setVisible(!visible)}
          >
            <div className={styles.selection}>
              {typeof value === "object" && value.content ? (
                <div style={{ paddingTop: 14 }}>{value.content}</div>
              ) : (
                value
              )}
            </div>
          </div>
        )}
        {visible && (
          <div
            className={cn(styles.body, {
              [styles.bodyUp]: upBody,
              [styles.scrollable]: isScrollable,
            })}
          >
            {options.map((x, index) => {
              const firstWord = getFirstWord(x);
              const tooltipContent = TooltipData[firstWord];

              return showOptionTooltips && tooltipContent ? (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)} // Set hover state to current index
                  onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                  })}
                  onClick={() => handleClick(x)}
                  style={{ display: "flex", alignItems: "center" }} // Flexbox for proper alignment
                >
                  {/* Help icon - appears only when the current title is hovered */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                      position: "absolute",
                    }}
                  >
                    <CustomTooltip
                      title={
                        <div>
                          <div
                            style={{
                              fontSize: "15px",
                              marginBottom: "10px",
                            }}
                          >
                            {tooltipContent.text}
                          </div>
                          <img
                            src={tooltipContent.imageUrl}
                            alt={firstWord}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "250px",
                              borderRadius: "8px",
                              margin: "0 auto",
                              display: "block",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        </div>
                      }
                      placement="left"
                      classes={{
                        tooltip: stylesTooltip["referencesOptionsTooltip"],
                      }}
                    >
                      <HelpOutlineIcon
                        style={{
                          fontSize: "17px",
                          // marginLeft: "0px",
                          // marginRight: "0px",
                          visibility:
                            hoveredIndex === index ? "visible" : "hidden", // Icon appears when hovered
                          opacity: hoveredIndex === index ? 1 : 0,
                          transition:
                            "opacity 0.08s ease-in-out, visibility 0.1s ease-in-out",
                          color: "#5c5c5d",
                        }}
                      />
                    </CustomTooltip>
                  </div>

                  {/* Option title */}
                  <div style={{ flexGrow: 1 }}>
                    {typeof x === "object" && x.content ? (
                      <>
                        <div>{x.label}</div>
                        {x.content}
                      </>
                    ) : (
                      x
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                  })}
                  onClick={() => handleClick(x)}
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)} // Set hover state to current index
                  onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
                  style={{ display: "flex", alignItems: "center" }} // Align icon and title
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                    }}
                  >
                    {/* <HelpOutlineIcon
                      style={{
                        fontSize: "16px",
                        marginLeft: "-3px",
                        marginRight: "5px",
                        visibility:
                          hoveredIndex === index
                            ? "visible"
                            : "hidden", // Icon appears when hovered
                        opacity: hoveredIndex === index ? 1 : 0, // Smooth fade
                        transition:
                          "opacity 0.1s ease-in-out, visibility 0.1s ease-in-out",
                        color: "#5c5c5d",
                      }}
                    /> */}
                  </div>

                  <div style={{ flexGrow: 1 }}>
                    {typeof x === "object" && x.content ? (
                      <>
                        <div>{x.label}</div>
                        {x.content}
                      </>
                    ) : (
                      x
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
