import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import styles from "./GenerateOfferScreen3.module.sass";
import { useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";
import Profile from "./Profile/";
import cn from "classnames";
import Icon from "../../components/Icon";
import { toast } from "react-hot-toast";
import ZipCreator from "../../services/ZipCreatorService";
import GenerationService from "../../services/GenerationService";
import { useAuth } from "../../contexts/AuthContext";
import CreationCard from "../../components/Product";
import { useParams } from "react-router-dom";
import { useModalPreview } from "../../contexts/ModalPreviewContext";

const finalQuestArtifactId = "dc055bde-9792-479e-934c-43ee19f825c6";
const tournamentLeaderboardArtifactId = "f52af31b-1d42-4592-9441-58656a220d73";
const tournamentBuyinArtifactId = "760c10df-ea70-4888-9f3f-e8c0c19f0994";
const innplayBackgroundArtifactId = "c4c0c88c-f549-438b-bffb-7b3bb0046827";
const innplayFinalArtifactId = "dc055bde-9792-479e-934c-43ee19f825c6";
const diceBackgroundArtifactId = "6f97b793-8add-466d-a08b-4a705c2a0fb8";
const diceRouterArtifactId = "188ce5b3-c53c-4a6e-995f-e4e6c15e682d";
const diceArtifactId = "188ce5b3-c53c-4a6e-995f-e4e6c15e682d";

const GenerateOfferScreen3 = () => {
  const { openModal } = useModalPreview();
  const [creations, setCreations] = useState([]);
  const [paginatedCreations, setPaginatedCreations] = useState([]);
  const [filteredCreations, setFilteredCreations] = useState([]);
  const [generationErrorMessage, setGenerationErrorMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState({
    "droppable-1": [{ is_place_holder: true }],
    "droppable-2": [{ is_place_holder: true }],
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [artifact, setArtifact] = useState(null);
  const [subArtifacts, setSubArtifacts] = useState([]);
  const [contentVisible, setContentVisible] = useState(true);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(true);
  const [loading, setLoading] = useState(true);
  const isNotSimple = true;
  const { organizationData } = useAuth();
  const [baseCreation, setBaseCreation] = useState(
    location.state?.baseCreation
  );
  const [currentViewingItemIndex, setCurrentViewingItemIndex] = useState();
  const { artifactId, creationType } = useParams();
  const [selectedSubArtifact, setSelectedSubArtifact] = useState(null);
  const [selectedSubArtifactIndex, setSelectedSubArtifactIndex] = useState();

  useEffect(() => {
    setSelectedSubArtifact(subArtifacts[selectedSubArtifactIndex]);
  }, [selectedSubArtifactIndex]);

  const toggleSubArtifact = (index) => {
    setSelectedSubArtifactIndex(index);
  };

  const handleDownload = async () => {
    const files = [
      {
        name: `decoy_1.png`,
        url: selectedItems["droppable-1"][0].result_image_url[0],
      },
      {
        name: `decoy_2.png`,
        url: selectedItems["droppable-2"][0].result_image_url[0],
      },
    ];

    await ZipCreator.createZipFile(selectedItems, "Quest");

    try {
      ApiService.post(
        `/download_creation/${selectedItems[0].creation_id}/${selectedItems[0].creation_sub_id}`
      );
      ApiService.post(
        `/download_creation/${selectedItems[1].creation_id}/${selectedItems[1].creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to download:", error);
    }
  };

  const onDownload = async (creation) => {
    console.log(selectedItems[1]);
    console.log(selectedItems[1].creation_id);
    console.log(selectedItems[1].creation_sub_id);

    const files = Object.values(selectedItems)
      .flat()
      .filter((item) => item && item.result_image_url) // Filters out any empty or falsy items
      .map((item, index) => ({
        name: `${item.creation_id}_${item.creation_sub_id}.png`,
        url: item.result_image_url[0],
      }));

    console.log(files);
    toast
      .promise(ZipCreator.createZipFile(files, "quest"), {
        loading: "Downloading Offer...",
        success: "Downloading was successful!",
        error: "Failed to create zip file. Please try again later.",
      })
      .then(async () => {
        try {
          ApiService.post(
            `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
          );
        } catch (error) {
          console.error("Failed to toggle like status:", error);
        }
      })
      .catch((error) => {
        console.error("Error during zip creation or download:", error);
      });
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const itemsLimit = 9;

  const toggleLikeStatus = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creations[index].liked;
    const updatedCreations = creations.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creationId}/${creationSubId}`,
          { liked: updatedStatus }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreations(updatedCreations);
            return updatedStatus
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const regenerateCreation = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.RegenerateCreation(creations[index]);
    fetchArtifactAndCreations();
  };

  const generateNewVariations = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }
    await GenerationService.GenerateNewVariations(creations[index]);
    fetchArtifactAndCreations();
  };

  const toggleOverviewVisibility = async (state, index) => {
    const filteredCreations = filterCreationsForDisplay(creations);
    const item = creations[index];
    const filteredIndex = filteredCreations.findIndex(
      (filteredItem) =>
        filteredItem.creation_id === item.creation_id &&
        filteredItem.creation_sub_id === item.creation_sub_id
    );
    setCurrentViewingItemIndex(filteredIndex);
  };

  const filterCreationsForDisplay = (creations) => {
    return creations.filter((item) => item.result_image_url);
  };

  useEffect(() => {
    if (artifactId) {
      fetchArtifactAndCreations();
    }
  }, [artifactId]);

  useEffect(() => {
    if (selectedSubArtifact) return;

    const newSelectedSubArtifactIndex = subArtifacts.findIndex(
      (subArtifact) => !subArtifact.hidden
    );
    setSelectedSubArtifactIndex(newSelectedSubArtifactIndex);
  }, [subArtifacts]);

  useEffect(() => {
    if (creations.length > 0) {
      const filtered = creations.filter(
        (creation) =>
          creation.artifact_id ===
          subArtifacts[selectedSubArtifactIndex]?.artifact_id
      );
      setFilteredCreations(filtered);
    }
  }, [selectedSubArtifactIndex, creations, subArtifacts]);

  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    colorPalette,
    settings,
  }) => {
    const timestamp = Date.now();
    // setCanGenerate(false);
    console.log(selectedSubArtifact.artifact_id);
    // block quest-final generation if quest background is not selected
    if (
      selectedSubArtifact.artifact_id == finalQuestArtifactId &&
      (selectedItems["1"]?.is_place_holder == true || !selectedItems["1"])
    ) {
      toast.error("Please choose a quest background before generating.");
      setCanGenerate(true);
      return;
    }
    const data = {
      prompt: prompt,
      numberOfImages: numImagesPerPrompt,
      isRemoveBackground,
      enrichPromptByChatGPTFlag,
      creationId,
      image,
      referenceType,
      dimensionsRatio,
      colorPalette,
      artifact: selectedSubArtifact,
      artifactId: selectedSubArtifact.artifact_id,
      settings,
    };

    toast.promise(
      (async () => {
        if (selectedSubArtifact.artifact_id == finalQuestArtifactId) {
          data.additionalCreationId = selectedItems["1"].creation_id;
          data.additionalCreationSubId = selectedItems["1"].creation_sub_id;
        }
        console.log(selectedSubArtifact.artifact_id == finalQuestArtifactId);
        console.log(data);

        const response = await GenerationService.startGeneration(data);

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.log(err);
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  const getBackgroundImage = (artifact, index, selectedItems) => {
    const comingSoonImage = `${process.env.PUBLIC_URL}/images/coming_soon.png`;

    const placeholderMapper = {
      [tournamentLeaderboardArtifactId]: `${process.env.PUBLIC_URL}/images/tournament-leaderboard-default-placeholder.png`,
      [tournamentBuyinArtifactId]: `${process.env.PUBLIC_URL}/images/tournament-buyin-default-placeholder.png`,
      [innplayBackgroundArtifactId]: `${process.env.PUBLIC_URL}/images/innplay_background.png`,
      [innplayFinalArtifactId]: `${process.env.PUBLIC_URL}/images/innplay_final.png`,
    };

    if (artifact.hidden) {
      return `url(${comingSoonImage})`;
    }
    if (selectedItems[index]?.result_image_url) {
      return `url(${selectedItems[index].result_image_url})`;
    }
    if (artifact.artifact_id in placeholderMapper) {
      return `url(${placeholderMapper[artifact.artifact_id]})`;
    }
  };

  const fetchArtifactAndCreations = async (
    initialLoad = false,
    startKey = null
  ) => {
    if (initialLoad) {
      setLoading(true);
      setContentVisible(false);
    }
    let artifactResponse = null;
    let creationsResponse;
    console.log("Fetching data...");

    try {
      if (artifactId) {
        const artifactResponse = await ApiService.get(
          `/get_artifact/${artifactId}`
        );
        setArtifact(artifactResponse.data.artifact);
        setSubArtifacts(artifactResponse.data.sub_artifacts);
        const newSubArtifacts = artifactResponse.data.sub_artifacts;

        let fetchedCreations = [];

        for (const subArtifact of newSubArtifacts) {
          console.log(
            "Fetching creations for sub-artifact: ",
            subArtifact.artifact_id
          );
          console.log("artifact_id:", subArtifact.artifact_id);
          const creationsResponse = await ApiService.get(
            `/get_creations_based_on_artifact_id?artifact_id=${subArtifact.artifact_id}&limit=${itemsLimit}`
          );
          console.log("Response: ", creationsResponse);
          fetchedCreations = fetchedCreations.concat(creationsResponse.data);
          console.log("Fetched Creations: ", fetchedCreations);
        }
        setCreations(fetchedCreations);
        setLastEvaluatedKey(artifactResponse.next_start_key);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      if (initialLoad) {
        setLoading(false);
      }
    }
  };

  const handleSelectItem = (creation, droppableId) => {
    setSelectedItems((prev) => ({
      ...prev,
      [droppableId]: creation,
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    let paginatedCreations = [];
    if (filteredCreations.length > 0) {
      paginatedCreations = filteredCreations.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
    }
    setPaginatedCreations(paginatedCreations);
  }, [filteredCreations, currentPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatusOfPendingCreations();
    }, 10000);

    return () => clearInterval(interval);
  }, [creations]);

  const checkStatusOfPendingCreations = async () => {
    const pendingCreations = creations?.filter(
      (creation) => creation.status === "pending"
    );
    if (!pendingCreations || pendingCreations.length === 0) {
      return;
    }

    const payload = pendingCreations.map((creation) => ({
      creation_id: creation.creation_id,
      creation_sub_id: creation.creation_sub_id,
    }));

    try {
      const response = await ApiService.post("/check_status", payload);
      console.log(response);
      const updatedCreations = response.results;

      setCreations((prevCreations) =>
        prevCreations.map((c) => {
          const updatedCreation = updatedCreations.find(
            (uc) =>
              uc.creation_id === c.creation_id &&
              uc.creation_sub_id === c.creation_sub_id
          );
          return updatedCreation && !updatedCreation.error
            ? { ...c, ...updatedCreation.data }
            : c;
        })
      );
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  return (
    <div
      className={`${styles.shop} ${
        generationErrorMessage ? styles.fadeIn : ""
      }`}
    >
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>
      {selectedSubArtifact && (
        <>
          <Profile
            artifact={selectedSubArtifact}
            generateCreations={generateCreations}
            error={generationErrorMessage}
            canGenerate={canGenerate}
            baseCreation={baseCreation}
          />

          <Card>
            <center>
              <div className={styles.toggleContainer}>
                {subArtifacts.map((subArtifact, index) => (
                  <div
                    key={index}
                    className={`${styles.toggleButton} ${
                      selectedSubArtifactIndex === index ? styles.active : ""
                    } ${subArtifact.hidden ? styles.disabled : ""}`}
                    onClick={() =>
                      !subArtifact.hidden && toggleSubArtifact(index)
                    }
                    style={{
                      pointerEvents: subArtifact.hidden ? "none" : "auto",
                    }}
                  >
                    {subArtifact.display_name.includes("-")
                      ? subArtifact.display_name
                          .split("-")[1]
                          .trim()
                          .split(" ")[0]
                          .charAt(0)
                          .toUpperCase() +
                        subArtifact.display_name
                          .split("-")[1]
                          .trim()
                          .split(" ")[0]
                          .slice(1)
                      : subArtifact.display_name || `Stage ${index + 1}`}{" "}
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={styles.wrap}>
                  <div
                    className={cn(styles.gallery, {
                      [styles.triple]: isNotSimple,
                    })}
                  >
                    {paginatedCreations.map((creation, index) => (
                      <div key={index} className={styles.productWrapper}>
                        <CreationCard
                          item={creation}
                          showPrompt={false}
                          fixedSize={200}
                          onLikeDislike={() =>
                            toggleLikeStatus(
                              creation.creation_id,
                              creation.creation_sub_id
                            )
                          }
                          onRegenerateClick={
                            artifactId
                              ? () =>
                                  regenerateCreation(
                                    creation.creation_id,
                                    creation.creation_sub_id
                                  )
                              : null
                          }
                          onGenerateVariationsClick={
                            artifactId && !artifact?.use_flux
                              ? () =>
                                  generateNewVariations(
                                    creation.creation_id,
                                    creation.creation_sub_id
                                  )
                              : null
                          }
                          onShowPreview={() =>
                            openModal(
                              filteredCreations,
                              index,
                              fetchArtifactAndCreations
                            )
                          }
                        />
                        {creation.status === "completed" && (
                          <button
                            className={styles.chooseButton}
                            onClick={() =>
                              handleSelectItem(
                                creation,
                                selectedSubArtifactIndex
                              )
                            }
                          >
                            Choose
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className={styles.bottomPanel}>
                    <div className={styles.pagination}>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Newer
                      </button>
                      <span>{currentPage}</span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage * itemsPerPage >= filteredCreations.length
                        }
                      >
                        Older
                      </button>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 15, marginRight: -20, height: 600 }}>
                  {artifact.artifact_id !== diceArtifactId &&
                    subArtifacts.map((subArtifact, index) => (
                      <center key={index}>
                        <div
                          key={index}
                          className={styles.pickedScreenContainer}
                        >
                          <div
                            className={cn(styles.pickedScreen)}
                            style={{
                              width:
                                subArtifact.artifact_id ===
                                  finalQuestArtifactId ||
                                subArtifact.artifact_id ===
                                  innplayBackgroundArtifactId
                                  ? 251.5
                                  : "",
                              height:
                                subArtifact.artifact_id === finalQuestArtifactId
                                  ? 338.75
                                  : subArtifact.artifact_id ===
                                    innplayBackgroundArtifactId
                                  ? 436.75
                                  : "",
                              backgroundImage: getBackgroundImage(
                                subArtifact,
                                index,
                                selectedItems
                              ),
                            }}
                          >
                            {!subArtifact.hidden &&
                              (subArtifact.artifact_id ===
                              innplayBackgroundArtifactId ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/items_only.png`}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 2,
                                    pointerEvents: "none",
                                  }}
                                />
                              ) : artifact.artifact_id ===
                                "eaeee3c1-7897-4152-aef4-d9450719b198" ? (
                                index === 1 ? (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/tournamentFull.png`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      zIndex: 2,
                                      pointerEvents: "none",
                                    }}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/tournamentLeaderboard.png`}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 4,
                                        pointerEvents: "none",
                                      }}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -101,
                                        left: 0,
                                        width: "100%",
                                        height: "calc(100% - 10px)",
                                        backgroundImage: selectedItems[index]
                                          ?.result_image_url
                                          ? `url(${selectedItems[index].result_image_url})`
                                          : null,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        zIndex: 1,
                                      }}
                                    />
                                  </>
                                )
                              ) : null)}
                          </div>
                          <div
                            className={cn(styles.backgroundIndicator, {
                              [styles.activeIndicatorTopTournament]:
                                artifact.artifact_id ===
                                  "eaeee3c1-7897-4152-aef4-d9450719b198" &&
                                index === 0,
                              [styles.activeIndicator]:
                                selectedSubArtifactIndex === index,
                            })}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: "136.6%",
                              height: "15%",
                              transform: "translateX(-100%)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {selectedSubArtifactIndex === index && (
                              <img
                                src={`${process.env.PUBLIC_URL}/images/svg/leftyArrow.png`}
                                alt="Active Indicator"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                          </div>
                        </div>
                      </center>
                    ))}

                  <center>
                    {artifact.artifact_id !== diceArtifactId && (
                      <div
                        className={cn("button button-small", styles.button)}
                        style={{ marginTop: 20 }}
                        onClick={onDownload}
                      >
                        <Icon name="download" size="24" />
                        <span
                          className={styles.download}
                          style={{
                            pointerEvents: "auto",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          Download Now
                        </span>
                      </div>
                    )}
                  </center>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: artifactId === diceArtifactId ? -450 : 0,
                }}
              >
                {artifact.artifact_id === diceArtifactId && (
                  <div
                    className={styles.diceContainer}
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        // backgroundImage: `url(${process.env.PUBLIC_URL}/images/diceBackground.png)`,
                        backgroundImage: selectedItems[0]?.result_image_url
                          ? `url(${selectedItems[0].result_image_url})`
                          : `url(${process.env.PUBLIC_URL}/images/diceBackground.png)`,
                        width: 500,
                        height: 250,
                        backgroundSize: "cover",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundImage: selectedItems[1]?.result_image_url
                          ? `url(${selectedItems[1].result_image_url})`
                          : `url(${process.env.PUBLIC_URL}/images/diceRoad.png)`,
                        width: 500,
                        height: 250,
                        position: "relative",
                        backgroundSize: "cover",
                        bottom: 250,
                        left: 0,
                        zIndex: 2,
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/diceUI.png)`,
                        width: 500,
                        height: 250,
                        position: "relative",
                        backgroundSize: "cover",
                        bottom: 500,
                        left: 0,
                        zIndex: 3,
                      }}
                    ></div>
                  </div>
                )}
              </div>

              {artifact.artifact_id === diceArtifactId && (
                <div
                  className={cn("button button-small", styles.button)}
                  onClick={onDownload}
                >
                  <Icon name="download" size="24" />
                  <span
                    className={styles.download}
                    style={{
                      pointerEvents: "auto",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    Download Now
                  </span>
                </div>
              )}
            </center>
          </Card>
        </>
      )}
    </div>
  );
};

export default GenerateOfferScreen3;
