import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";

const Loader = ({ className, white }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Start a timer when the component mounts
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(seconds >= 1000){
      setSeconds(0);
    }
  }, [seconds]);

  return (
    <div className={styles.loaderContainer}>
      <div
        className={cn(styles.loader, className, {
          [styles.loaderWhite]: white,
        })}
      />
        <div className={styles.timer}>{seconds}</div>
    </div>
  );
};

export default Loader;
