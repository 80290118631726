import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import styles from "./GenerateOfferScreen.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import ActivePassivePromptBox from "../../components/Header/ActivePassivePromptBox";
import Control from "../../components/Product/Control";
import ZipCreator from "../../services/ZipCreatorService";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";

const tripleRollingOfferArtifactId = "4baa24aa-a711-4f3d-907b-b88fe27d0d29";

const RollingOfferCard = ({ imageUrl, children, width, showItems }) => {
  const style = {
    backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
    backgroundSize: "100% 100%",
    width: width ? width : 270,
    height: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
  };

  return (
    <div>
      <div style={style}>
        {children}
        {imageUrl ? (
          showItems ? (
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/rollingOfferItems.png`}
                alt="Items"
                style={{ width: 200 }}
              />
            </div>
          ) : (
            ""
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export const TripleRollingOfferOption = ({ creation, onLike, onDownload }) => {
  return (
    <div
      className="rollingOfferCard"
      style={{
        height: "633px",
        minWidth: "292.5px",
        paddingTop: 10,
        width: "auto",
        gap: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        backgroundSize: "100% 100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/RollingTripleOfferBg.png`})`,
      }}
    >
      <RollingOfferCard
        imageUrl={
          creation.components_details.title
            ? creation.components_details.title.result_image_url[0]
            : ""
        }
        showItems={false}
      />
      <RollingOfferCard
        imageUrl={
          creation.components_details.active_passive
            ? creation.components_details.active_passive.result_image_url[0]
            : ""
        }
        showItems={true}
      />
      <RollingOfferCard
        imageUrl={
          creation.components_details.active_passive
            ? creation.components_details.active_passive.result_image_url[1]
            : ""
        }
        showItems={true}
      />
      <RollingOfferCard
        imageUrl={
          creation.components_details.active_passive
            ? creation.components_details.active_passive.result_image_url[1]
            : ""
        }
        showItems={true}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
        {creation?.status == "completed" && (
          <Control
            className={styles.control}
            item={creation}
            onLikeDislike={onLike}
            onDownload={onDownload}
          />
        )}
      </div>
    </div>
  );
};

export const PersonalOffer6Option = ({ creation, onLike, onDownload }) => {
  const arrowStyles = [
    { position: "relative", left: 88, height: 30, transform: "rotate(0deg)" },
    {
      position: "relative",
      top: 67,
      left: 20,
      height: 30,
      transform: "rotate(90deg)",
    },
    {
      position: "relative",
      top: 67,
      height: 30,
      left: 20,
      transform: "rotate(90deg)",
    },
    {
      position: "relative",
      right: 67,
      left: -50,
      height: 30,
      transform: "rotate(180deg)",
    },
  ];

  return (
    <div
      className="rollingOfferCard"
      style={{
        height: "633px",
        width: "292.5px",
        borderRadius: 10,
        paddingTop: 10,
        gap: 0,
        display: "flex",
        flexDirection: "column",
        backgroundSize: "100% 100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/RollingTripleOfferBg.png`})`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RollingOfferCard
          imageUrl={
            creation.components_details.title
              ? creation.components_details.title.result_image_url[0]
              : ""
          }
        />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <RollingOfferCard
            imageUrl={
              creation.components_details.active_passive
                ? creation.components_details.active_passive.result_image_url[0]
                : ""
            }
            width={125}
          >
            {creation.components_details.active_passive.result_image_url[0] && (
              <>
                <img
                  src={process.env.PUBLIC_URL + `/images/present.png`}
                  style={{
                    position: "relative",
                    width: 50,
                    marginTop: -18,
                    left: 60,
                  }}
                />
                <img
                  src={process.env.PUBLIC_URL + `/images/arrow_1.png`}
                  style={arrowStyles[0]}
                />
                <img
                  src={process.env.PUBLIC_URL + `/images/free_button.png`}
                  style={{
                    position: "relative",
                    width: 90,
                    left: -36,
                    top: 32,
                  }}
                />
              </>
            )}
          </RollingOfferCard>
          {creation.components_details.active_passive.result_image_url[0] &&
            Array.from({ length: 5 }, (_, index) => (
              <RollingOfferCard
                key={index}
                imageUrl={
                  creation.components_details.active_passive
                    ? creation.components_details.active_passive
                        .result_image_url[1]
                    : ""
                }
                width={125}
              >
                {index !== 4 ? (
                  <>
                    <img
                      src={process.env.PUBLIC_URL + `/images/present.png`}
                      style={{
                        position: "relative",
                        width: 50,
                        marginTop: -18,
                        left: 60,
                      }}
                    />
                    <img
                      src={process.env.PUBLIC_URL + `/images/arrow_1.png`}
                      style={arrowStyles[(index + 1) % 4]}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/images/free_button_locked.png`
                      }
                      style={{
                        position: "relative",
                        width: 90,
                        left: -36,
                        top: 32,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={process.env.PUBLIC_URL + `/images/present.png`}
                      style={{
                        position: "relative",
                        width: 50,
                        marginTop: -18,
                        left: 60,
                      }}
                    />
                    <img
                      src={process.env.PUBLIC_URL + `/images/arrow_1.png`}
                      style={arrowStyles[(index + 1) % 4]}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/images/free_button_locked.png`
                      }
                      style={{
                        position: "relative",
                        width: 90,
                        left: -38,
                        top: 32,
                      }}
                    />
                  </>
                )}
              </RollingOfferCard>
            ))}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
        {creation?.status === "completed" && (
          <Control
            className={styles.control}
            item={creation}
            onLikeDislike={onLike}
            onDownload={onDownload}
          />
        )}
      </div>
    </div>
  );
};

const GenerateOfferScreen = () => {
  const [creations, setCreations] = useState([]);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(false);
  const navigate = useNavigate();
  const [activePrompt, setActivePrompt] = useState("");
  const [passivePrompt, setPassivePrompt] = useState("");
  const pathname = location.pathname;
  const { organizationData } = useAuth();
  const offerType =
    pathname === "/triple-rolling-offer"
      ? "triple-rolling-offer"
      : "personal-offer-6";
  const artifactId =
    pathname === "/triple-rolling-offer"
      ? "4baa24aa-a711-4f3d-907b-b88fe27d0d29"
      : "7f9ad9b4-85a9-47d0-ad6f-21533721e85e";

  const handleOnRegenerateClicked = async () => {
    setCanGenerate(false);

    const requestData = {
      artifact_id: artifactId,
      prompt: [activePrompt, passivePrompt],
      artifact_type: "offer",
      direction: "left",
      input_image_url:
        creations[0].components_details?.title.based_on_image_url,
      fixed_title: creations[0].components_details?.title.result_image_url,
    };
    console.log(requestData);
    const response = await ApiService.post("/start_generation", requestData);
    fetchArtifactAndProducts();
  };

  useEffect(() => {
    if (
      creations?.length > 0 &&
      creations.every((creation) => creation?.status === "completed")
    ) {
      setCanGenerate(true);
    } else {
      setCanGenerate(false);
    }
  }, [creations]);

  const fetchArtifactAndProducts = async () => {
    try {
      // if creations and all of them are not completed
      if (
        creations?.length == 0 ||
        creations?.some((creation) => creation?.status !== "completed")
      ) {
        const limit = artifactId === tripleRollingOfferArtifactId ? 3 : 2;
        const creationsResponse = await ApiService.get(
          `/get_creations_based_on_artifact_id?artifact_id=${artifactId}&limit=${limit}`
        );
        setCreations(creationsResponse.data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch creation data. Please try again later.");
      setCanGenerate(true);
    }
  };

  useEffect(() => {
    setCreations([]);
    if (location?.state) {
      const { requestData } = location.state;
      if (requestData) {
        navigate(location.pathname, { replace: true, state: null });
      }
    } else {
      fetchArtifactAndProducts();
    }
  }, [location, artifactId, navigate]);

  useEffect(() => {
    const fetchCreations = async () => {
      try {
        const limit = artifactId === tripleRollingOfferArtifactId ? 3 : 2;
        const response = await ApiService.get(
          `/get_artifact/${artifactId}?limit=${limit}`
        );
        console.log(response);
        setCreations(response.data.latest_creations);
      } catch (error) {
        console.error("Error fetching creations:", error);
      }
    };

    fetchCreations();
  }, [artifactId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchArtifactAndProducts();
    }, 10000);

    return () => clearInterval(interval);
  }, [artifactId]);

  const onDownload = async (creation) => {
    console.log(creation);
    const files = [
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_active.png`,
        url: creation.components_details.active_passive.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_passive.png`,
        url: creation.components_details.active_passive.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title.png`,
        url: creation.components_details.title.result_image_url[0],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title1.png`,
        url: creation.components_details.title.result_image_url[1],
      },
      {
        name: `${creation.creation_id}_${creation.creation_sub_id}_title2.png`,
        url: creation.components_details.title.result_image_url[2],
      },
    ];
    toast
      .promise(
        ZipCreator.createZipFile(
          files,
          creation.creation_id + "_" + creation.creation_sub_id
        ),
        {
          loading: "Downloading Offer...",
          success: "Downloading was successful!",
          error: "Failed to create zip file. Please try again later.",
        }
      )
      .then(async () => {
        try {
          ApiService.post(
            `/download_creation/${creation.creation_id}/${creation.creation_sub_id}`
          );
        } catch (error) {
          console.error("Failed to toggle like status:", error);
        }
      })
      .catch((error) => {
        console.error("Error during zip creation or download:", error);
      });
  };

  const onLike = async (creation) => {
    let { creation_sub_id, creation_id } = creation;

    const index = creations.findIndex(
      (c) =>
        c.creation_id === creation_id && c.creation_sub_id === creation_sub_id
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedCreations = creations.map((c, i) => {
      if (i === index) {
        return { ...c, liked: !c.liked };
      }
      return c;
    });
    // setCreations(updatedCreations);

    toast
      .promise(
        ApiService.post(
          `/like_or_dislike_creation/${creation_id}/${creation_sub_id}`,
          { liked: updatedCreations[index].liked }
        ),
        {
          loading: "Updating like status...",
          success: () => {
            setCreations(updatedCreations);
            return updatedCreations
              ? "Liked successfully!"
              : "Unliked successfully!";
          },
          // console.log(`Liked status updated to:`, updatedCreations[index].liked);
          error: "Failed to update like status. Please try again later.",
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const titleCase = (str) => {
    return str
      .replace(/-/g, " ") // Replace all hyphens with spaces
      .toLowerCase() // Convert the string to lowercase
      .split(" ") // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the array back into a string
  };

  return (
    <div className={`${styles.shop} ${styles.fadeIn}`}>
      <div className={styles.background}>
        <img src={organizationData?.app_bg} alt="Background" />
      </div>

      <Card className={styles.card}>
        <h1>{titleCase(offerType)}</h1>
        <div style={{ textAlign: "right" }}>
          <button onClick={() => navigate(-1)}>
            Back to the character screen
            <Icon name="logout" size="20" />
          </button>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
            {creations?.map((creation, index) =>
              // check for the location
              pathname === "/triple-rolling-offer" ? (
                <TripleRollingOfferOption
                  key={index}
                  creation={creation}
                  onLike={() => onLike(creation)}
                  onDownload={() => onDownload(creation)}
                />
              ) : (
                <PersonalOffer6Option
                  key={index}
                  creation={creation}
                  onLike={() => onLike(creation)}
                  onDownload={() => onDownload(creation)}
                />
              )
            )}
          </div>
          <div>
            {canGenerate && (
              <ActivePassivePromptBox
                canGenerate={canGenerate}
                OnGenerateClicked={() => handleOnRegenerateClicked()}
                activePrompt={activePrompt}
                setActivePrompt={setActivePrompt}
                passivePrompt={passivePrompt}
                setPassivePrompt={setPassivePrompt}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GenerateOfferScreen;
