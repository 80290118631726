import React, { useState, useRef, useEffect } from "react";
import Image from "../Image";
import styles from "../ColorPicker/ColorPicker.module.sass";
import { ChromePicker } from "react-color";
import { Box } from "@mui/material";
import CustomTooltip from "../CustomTooltip/index";
import TooltipData from "../CustomTooltip/TooltipsData";

const Painter = ({ setBrushColor, setPickedColor }) => {
  const [displayPainter, setDisplayPainter] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("");
  // const[pickedColor, setPickedColor] = useState('')
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClick = () => {
    setDisplayPainter(!displayPainter);
  };

  const handleClose = () => {
    setDisplayPainter(false);
  };

  const handleClear = () => {
    setColor("");
    setPickedColor();
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handlePick = (color) => {
    console.log("Color picked:", color);
    setPickedColor(color);
    setBrushColor(color);
    setDisplayColorPicker(false);
  };

  // const handleChooseFromHistory = (event) => {
  //     const newColor = event.target.style.backgroundColor; // Get the background color from the clicked button
  //     setColor(newColor); // Update the color to the chosen history color
  //     setMasterColor(newColor);
  //     console.log('Color changed to:', newColor);
  // }

  useEffect(() => {
    const adjustPickerPosition = () => {
      if (pickerRef.current && buttonRef.current) {
        const pickerRect = pickerRef.current.getBoundingClientRect();
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Default positions
        let newLeft = "53px";
        let newTop = "6px";

        // Check if picker exceeds the right edge of the screen
        if (pickerRect.right > viewportWidth) {
          newLeft = `${-pickerRect.width + 4}px`; // Adjust to position left of the button
        }
        // if (pickerRect.bottom > viewportHeight && pickerRect.right <= viewportWidth) {
        //     newTop = `${-pickerRect.height + 1}px`; // Adjust to position left of the button
        // }

        pickerRef.current.style.left = newLeft;
        // pickerRef.current.style.top = newTop;
      }
    };

    if (displayPainter) {
      adjustPickerPosition();
      window.addEventListener("resize", adjustPickerPosition);
    }

    return () => {
      window.removeEventListener("resize", adjustPickerPosition);
    };
  }, [displayPainter]);

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is inside the picker container
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Check if the click is not on the scrollbar
        if (
          event.target.tagName !== "HTML" && // Prevent closing on scrollbar clicks
          event.target.tagName !== "BODY" &&
          event.target.tagName !== "BUTTON" && // Prevent closing when clicking on the button itself
          event.target.tagName !== "IMG" // Prevent closing when clicking on the image
        ) {
          handleClose();
        }
      }
    }

    if (displayPainter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayPainter]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* {pickedColor && (
        <div
            onClick={handleClick}
            style={{
                    backgroundColor: pickedColor,
                    borderRadius: "50%",
                    width: "37px",
                    height: "37px",
                    marginTop: "5px", // Spacing below the color palette icon
                    marginLeft: "11px"// Spacing next to the color palette icon
                    }}
                ></div>
            )} */}
      <Box
        ref={pickerRef}
        className={styles.ColorPicker}
        style={{
          position: "absolute",
          zIndex: 1001,
          top: "100%", // Place the box directly below the top button
          left: "50%", // Start at the middle of the top button
          transform: "translateX(-50%)", // Shift the box left by 50% of its width to center it
          marginTop: "-2px", // Optional: Add some space between the button and the color picker
          background: "#d9d9d9",
          padding: "8px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <button
          onClick={() => handlePick("white")}
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#000000")}
          style={{
            backgroundColor: "#000000",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#dd262a")}
          style={{
            backgroundColor: "#dd262a",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#eb5711")}
          style={{
            backgroundColor: "#eb5711",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#ebb20c")}
          style={{
            backgroundColor: "#ebb20c",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#14a24a")}
          style={{
            backgroundColor: "#14a24a",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#1b66eb")}
          style={{
            backgroundColor: "#1b66eb",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#933be9")}
          style={{
            backgroundColor: "#933be9",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={() => handlePick("#dc2978")}
          style={{
            backgroundColor: "#dc2978",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
          }}
        />
        <button
          onClick={toggleColorPicker}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/svg/colorWheel.png)`,
            backgroundSize: "112% 112%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            zIndex: 100001,
          }}
        />
      </Box>
      {displayColorPicker && (
        <Box
          ref={pickerRef}
          className={styles.ColorPicker}
          style={{
            position: "absolute",
            // position: 'fixed',
            // zIndex: 5,
            zIndex: 9999,
            left: "450px",
            top: "18px", // Position below the button
            background: "white",
            padding: "10px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ChromePicker color={color} onChange={handleChange} />
          <button
            onClick={handleClear}
            style={{
              marginTop: "10px",
              cursor: "pointer",
              background: "#9CA5A6",
              color: "white",
              fontSize: "15px",
              borderRadius: "10px",
              width: "100px",
              height: "25px",
            }}
          >
            Clear
          </button>
          <button
            style={{
              marginTop: "8px",
              padding: "8px 16px",
              cursor: "pointer",
              background: color,
              color: "white",
              fontSize: "20px",
              border: "none",
              borderRadius: "10px",
              width: "150px",
              height: "40px",
              marginBottom: "10px",
            }}
            onClick={() => handlePick(color)}
          >
            Pick
          </button>
        </Box>
      )}
    </div>
  );
};
export default Painter;
