import toast from "react-hot-toast";
import ApiService from "./ApiService";
import { convertImageToBase64 } from "../utils";

const GenerationService = {
  tripleRollingOfferArtifactId: "4baa24aa-a711-4f3d-907b-b88fe27d0d29",
  personalOffer6ArtifactId: "7f9ad9b4-85a9-47d0-ad6f-21533721e85e",

  async prepareImage(image) {
    if (image) {
      return typeof image === "string"
        ? { sketch_image_url: image }
        : { sketch_image: await convertImageToBase64(image) };
    }
    return {};
  },

  async prepareMaskImage(maskImage) {
    if (maskImage) {
      return {
        mask_image: await convertImageToBase64(maskImage),
        // reference_type: "inpainting",
      };
    }
    return {};
  },

  async createRequestData(options) {
    const {
      prompt,
      isRemoveBackground,
      enrichPromptByChatGPTFlag,
      creationId,
      creationSubId,
      image,
      referenceType,
      dimensionsRatio,
      colorPalette,
      artifact,
      artifactId,
      maskImage,
      settings,
      masterColor,
      additionalCreationId,
      additionalCreationSubId,
    } = options;

    const requestData = {
      artifact_id: artifactId,
      prompt,
      is_remove_background: isRemoveBackground,
      enrich_prompt_by_chatgpt: enrichPromptByChatGPTFlag,
      artifact_type: artifact?.type,
      creation_id: creationId,
      creation_sub_id: creationSubId,
      color_palette: colorPalette,
      dimensions_ratio: dimensionsRatio,
      reference_type: options.referenceType,
      settings: settings,
      master_color: masterColor,
      additional_creation_id: additionalCreationId,
      additional_creation_sub_id: additionalCreationSubId,
    };

    return {
      ...requestData,
      ...(await GenerationService.prepareImage(image)),
      ...(await GenerationService.prepareMaskImage(maskImage)),
    };
  },

  async apiRequestWithToast(promise, messages) {
    return toast.promise(promise, messages);
  },

  async startGeneration(options) {
    console.log(options);
    const requestData = await GenerationService.createRequestData(options);
    console.log(requestData);
    const response = await ApiService.post("/start_generation", requestData);
    return response;
  },

  GenerateTripleRollingOffer: async (item) => {
    const requestData = {
      artifact_id: GenerationService.tripleRollingOfferArtifactId,
      prompt: item.prompt,
      artifact_type: "offer",
      input_image_url: item.result_image_url,
      direction: "left",
    };

    return GenerationService.apiRequestWithToast(
      ApiService.post("/start_generation", requestData),
      {
        loading: "Generating offer...",
        success: "Generation Started Successfully!",
        error: "Failed to generate offer.",
      }
    );
  },

  GenerateDecoy: async (item, navigate) => {
    navigate("/decoy", { state: { baseCreation: item } });
  },

  GenerateNewVariations: async (creation) => {
    return GenerationService.apiRequestWithToast(
      GenerationService.startGeneration({
        prompt: creation.prompt,
        numImagesPerPrompt: 4,
        isRemoveBackground: false,
        enrichPromptByChatGPTFlag: false,
        creationId: creation.creation_id,
        creationSubId: creation.creation_sub_id,
        artifactId: creation.artifact_id,
      }),
      {
        loading: "Starting generation...",
        success: "Generation Started Successfully!",
        error: "Generation Failed!",
      }
    );
  },

  RegenerateCreation: async (creation) => {
    return GenerationService.apiRequestWithToast(
      GenerationService.startGeneration({
        numImagesPerPrompt: 4,
        isRemoveBackground: false,
        enrichPromptByChatGPTFlag: true,
        artifactId: creation.artifact_id,
        creationId: creation.creation_id,
        creationSubId: creation.creation_sub_id,
      }),
      {
        loading: "Starting generation...",
        success: "Generation Started Successfully!",
        error: "Generation Failed!",
      }
    );
  },

  GeneratePersonalOffer6: async (item) => {
    const requestData = {
      artifact_id: GenerationService.personalOffer6ArtifactId,
      prompt: item.prompt,
      artifact_type: "offer",
      input_image_url: item.result_image_url,
      direction: "left",
    };

    return GenerationService.apiRequestWithToast(
      ApiService.post("/start_generation", requestData),
      {
        loading: "Generating offer...",
        success: "Offer generated!",
        error: "Failed to generate offer.",
      }
    );
  },
};

export default GenerationService;
