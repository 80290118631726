// User.js
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useAuth } from "../../../contexts/AuthContext"; // Adjust the import path to your AuthProvider's location
import { useDialog } from "../../../contexts/DialogContext"; // Adjust the import path to your DialogProvider's location
import OrganizationSwitcher from "../../organiztionSwitcher";

const items = [
  {
    menu: [
      {
        title: "Log out",
        action: "logout",
      },
    ],
  },
];

const User = ({ className }) => {
  const { showDialog, closeDialog } = useDialog();
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const { organizationData, artifacts, logout } = useAuth();

  if (!organizationData) return logout();
  const full_email = JSON.parse(localStorage.getItem("currentUser")).username;
  const organization = organizationData.display_name;
  const handleItemClick = (action) => {
    if (action === "logout") {
      showDialog(
        "Are you sure you want to log out?",
        () => {
          closeDialog();
          logout();
        },
        () => "No",
        "Yes"
      );
    } else {
      closeDialog();
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            id="profile"
          >
            <g fill="none" fill-rule="evenodd">
              <g fill="#000" transform="translate(-140 -2159)">
                <g transform="translate(56 160)">
                  <path d="M100.563 2017H87.438c-.706 0-1.228-.697-.961-1.338 1.236-2.964 4.14-4.662 7.523-4.662 3.384 0 6.288 1.698 7.524 4.662.267.641-.255 1.338-.961 1.338m-10.646-12c0-2.206 1.832-4 4.083-4 2.252 0 4.083 1.794 4.083 4s-1.831 4-4.083 4c-2.251 0-4.083-1.794-4.083-4m14.039 11.636c-.742-3.359-3.064-5.838-6.119-6.963 1.619-1.277 2.563-3.342 2.216-5.603-.402-2.623-2.63-4.722-5.318-5.028-3.712-.423-6.86 2.407-6.86 5.958 0 1.89.894 3.574 2.289 4.673-3.057 1.125-5.377 3.604-6.12 6.963-.27 1.221.735 2.364 2.01 2.364h15.892c1.276 0 2.28-1.143 2.01-2.364"></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <div className={styles.body}>
          <h3 id="user_email">{full_email}</h3>
          <h4> {organization}</h4>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    id="logoutButton"
                    onClick={() => handleItemClick(x.action)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
          {/* <OrganizationSwitcher onLogout={logout}/> */}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
