import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import styles from "./GenerateOfferScreen2.module.sass";
import { useLocation } from "react-router-dom";
import Draggable from "../../components/Draggable";
import Droppable from "../../components/Droppable";
import ApiService from "../../services/ApiService";
import Product from "../../components/Product";
import { DndContext, closestCenter } from "@dnd-kit/core";
import Profile from "./Profile/";
import cn from "classnames";
import Icon from "../../components/Icon";
import { toast } from "react-hot-toast";
import ZipCreator from "../../services/ZipCreatorService";
import GenerationService from "../../services/GenerationService";
import { useAuth } from "../../contexts/AuthContext";

const GenerateOfferScreen2 = () => {
  const [creations, setCreations] = useState([]);
  const [generationErrorMessage, setGenerationErrorMessage] = useState("");
  const [droppedItems, setDroppedItems] = useState({
    "droppable-1": [{ is_place_holder: true }],
    "droppable-2": [{ is_place_holder: true }],
    "droppable-3": [{ is_place_holder: true }],
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [artifact, setArtifact] = useState(null);
  const [contentVisible, setContentVisible] = useState(true);
  const location = useLocation();
  const [canGenerate, setCanGenerate] = useState(true);
  const [loading, setLoading] = useState(true);
  const creationType = "item";
  const isNotSimple = true;
  const { organizationData } = useAuth();
  const [baseCreation, setBaseCreation] = useState(
    location.state?.baseCreation
  );
  const [artifactId, setArtifactId] = useState(
    location.state?.baseCreation.artifact_id
  );
  const [currentViewingItemIndex, setCurrentViewingItemIndex] = useState();

  const handleDownloadDecoy = async () => {
    console.log(droppedItems["droppable-1"]);
    const files = [
      {
        name: `decoy_1.png`,
        url: droppedItems["droppable-1"][0].result_image_url[0],
      },
      {
        name: `decoy_2.png`,
        url: droppedItems["droppable-2"][0].result_image_url[0],
      },
      {
        name: `decoy_3.png`,
        url: droppedItems["droppable-3"][0].result_image_url[0],
      },
    ];

    await ZipCreator.createZipFile(files, "decoy");

    try {
      ApiService.post(
        `/download_creation/${droppedItems[0].creation_id}/${droppedItems[0].creation_sub_id}`
      );
      ApiService.post(
        `/download_creation/${droppedItems[1].creation_id}/${droppedItems[1].creation_sub_id}`
      );
      ApiService.post(
        `/download_creation/${droppedItems[2].creation_id}/${droppedItems[2].creation_sub_id}`
      );
    } catch (error) {
      console.error("Failed to toggle like status:", error);
    }
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const itemsLimit = 9;
  const toggleLikeStatus = async (creationId, creationSubId) => {
    const index = creations.findIndex(
      (c) => c.creation_id === creationId && c.creation_sub_id === creationSubId
    );
    if (index === -1) {
      toast.error("Creation not found. Please try again later.");
      return;
    }

    const updatedStatus = !creations[index].liked;
    const updatedCreations = creations.map((creation, i) => {
      if (i === index) {
        return { ...creation, liked: updatedStatus };
      }
      return creation;
    });

    try {
      ApiService.post(
        `/like_or_dislike_creation/${creationId}/${creationSubId}`,
        { liked: updatedStatus }
      );
      setCreations(updatedCreations);
    } catch (error) {
      console.error("Failed to toggle like status:", error);
    }
  };

  const toggleOverviewVisibility = async (state, index) => {
    const filteredCreations = filterCreationsForDisplay(creations);
    const item = creations[index];
    const filteredIndex = filteredCreations.findIndex(
      (filteredItem) =>
        filteredItem.creation_id === item.creation_id &&
        filteredItem.creation_sub_id === item.creation_sub_id
    );
    setCurrentViewingItemIndex(filteredIndex);
  };

  const filterCreationsForDisplay = (creations) => {
    return creations.filter((item) => item.result_image_url);
  };

  useEffect(() => {
    if (artifactId) {
      const interval = setInterval(() => {
        fetchArtifactAndCreations();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [artifactId]);
  const generateCreations = ({
    prompt,
    numImagesPerPrompt,
    isRemoveBackground,
    enrichPromptByChatGPTFlag,
    creationId = null,
    image,
    referenceType,
    dimensionsRatio,
    colorPalette,
    settings,
  }) => {
    const timestamp = Date.now();
    setCanGenerate(false);

    console.log(image);

    toast.promise(
      (async () => {
        const response = await GenerationService.startGeneration({
          prompt: prompt,
          numberOfImages: numImagesPerPrompt,
          isRemoveBackground,
          enrichPromptByChatGPTFlag,
          creationId,
          image,
          referenceType,
          dimensionsRatio,
          colorPalette,
          artifact,
          artifactId,
          settings,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        await fetchArtifactAndCreations();
        return response;
      })(),
      {
        loading: "Generating creations...",
        success: () => {
          setCanGenerate(true);
          return "Creations generated successfully!";
        },
        error: (err) => {
          console.error("Failed to initiate generation:", err);
          setCreations((prevCreations) =>
            prevCreations.filter(
              (creation) =>
                !creation.creation_id.startsWith("temp_" + timestamp)
            )
          );
          setCanGenerate(true);
          return "Failed to initiate generation. Please try again later.";
        },
      }
    );
  };

  const fetchArtifactAndCreations = async (
    initialLoad = false,
    startKey = null
  ) => {
    if (initialLoad) {
      setLoading(true);
      setContentVisible(false);
    }
    let artifactResponse = null;
    let creationsResponse;
    console.log("Fetching data...");

    try {
      if (artifactId) {
        artifactResponse = await ApiService.get(`/get_artifact/${artifactId}`);
        console.log(artifactResponse);
        setArtifact(artifactResponse.data.artifact);
        creationsResponse = await ApiService.get(
          `/get_creations_based_on_artifact_id?artifact_id=${artifactId}&limit=${itemsLimit}`
        );
        console.log(creationsResponse);
        setCreations(creationsResponse.data);
      } else if (creationType) {
        console.log(creationType);
        setArtifact(null);
        let url = `/get_liked_creations?creation_type=${creationType}`;
        if (startKey) {
          url += `&start_key=${startKey}`;
        }
        console.log(url);
        artifactResponse = await ApiService.get(url);
        console.log(!!artifactResponse.next_start_key);
        if (!startKey) {
          setCreations(artifactResponse.data);
        } else {
          setCreations((prev) => [...prev, ...artifactResponse.data]);
        }
      } else {
        setArtifact(null);
        artifactResponse = await ApiService.get(
          `/get_liked_creations?start_key=${startKey}`
        );
        if (initialLoad) {
          setCreations(artifactResponse.data);
        } else {
          setCreations((prev) => [...prev, ...artifactResponse.data]);
        }
      }
      setLastEvaluatedKey(artifactResponse.next_start_key);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      if (initialLoad) {
        setLoading(false);
      }
    }
  };

  const handleDragEnd = (event) => {
    const { over, active } = event;
    if (over && active.id !== over.id) {
      const droppedItem = creations.find(
        (creation) =>
          `draggable-${creation.creation_id}${creation.creation_sub_id}` ===
          active.id
      );

      if (droppedItem) {
        const clonedItem = { ...droppedItem, showPrompt: false };
        setDroppedItems((prev) => ({
          ...prev,
          [over.id]: [clonedItem],
        }));
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedCreations = creations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div
        className={`${styles.shop} ${
          generationErrorMessage ? styles.fadeIn : ""
        }`}
      >
        <div className={styles.background}>
          <img src={organizationData?.app_bg} alt="Background" />
        </div>

        <Profile
          artifact={artifact}
          generateCreations={generateCreations}
          error={generationErrorMessage}
          canGenerate={canGenerate}
          baseCreation={baseCreation}
        />

        <Card className={styles.card}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={styles.wrap}>
              <div
                style={{
                  height: "700px",
                  backgroundColor: "rgba(0,0,0,0.08",
                  borderRadius: 10,
                  width: "800px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={cn(styles.products, {
                  [styles.triple]: isNotSimple,
                })}
              >
                {paginatedCreations.map((creation, index) => (
                  <Draggable
                    key={
                      "draggable-1" +
                      creation.creation_id +
                      creation.creation_sub_id
                    }
                    id={
                      "draggable-" +
                      creation.creation_id +
                      creation.creation_sub_id
                    }
                    className={styles.product}
                  >
                    <Product
                      className={styles.product}
                      item={creation}
                      key={index}
                      showPrompt={false}
                      fixedSize={200}
                    />
                  </Draggable>
                ))}
              </div>

              <div className={styles.bottomPanel}>
                <div className={styles.pagination}>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Newer
                  </button>
                  <span>{currentPage}</span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= creations.length}
                  >
                    Older
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div
                className={styles.dropZone}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/decoy_background.png)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  width: 300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Droppable
                  id="droppable-1"
                  style={{
                    width: 100,
                    height: 100,
                    position: "relative",
                    top: "10%",
                  }}
                >
                  {droppedItems["droppable-1"].map((item, index) => (
                    <Product
                      className={styles.product}
                      item={item}
                      key={index}
                      showPrompt={false}
                      fixedSize={100}
                    />
                  ))}
                </Droppable>
                <Droppable
                  id="droppable-2"
                  style={{
                    width: 100,
                    height: 100,
                    position: "relative",
                    top: "2%",
                  }}
                >
                  {droppedItems["droppable-2"].map((item, index) => (
                    <Product
                      className={styles.product}
                      item={item}
                      key={index}
                      showPrompt={false}
                      fixedSize={100}
                    />
                  ))}
                </Droppable>
                <Droppable
                  id="droppable-3"
                  style={{
                    width: 100,
                    height: 100,
                    position: "relative",
                    top: "-14%",
                  }}
                >
                  {droppedItems["droppable-3"].map((item, index) => (
                    <Product
                      className={styles.product}
                      item={item}
                      key={index}
                      showPrompt={false}
                      fixedSize={100}
                    />
                  ))}
                </Droppable>
              </div>
              {droppedItems["droppable-1"][0].result_image_url &&
                droppedItems["droppable-2"][0].result_image_url &&
                droppedItems["droppable-3"][0].result_image_url && (
                  <center>
                    <div
                      className={cn("button button-small", styles.button)}
                      style={{ marginTop: 20 }}
                      onClick={handleDownloadDecoy}
                    >
                      <Icon name="download" size="24" />
                      <span
                        className={styles.download}
                        style={{
                          pointerEvents: "auto",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        Download Now
                      </span>
                    </div>
                  </center>
                )}
            </div>
          </div>
        </Card>
      </div>
    </DndContext>
  );
};

export default GenerateOfferScreen2;
