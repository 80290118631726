export const fetchArtifactsFromLocalStorageByType = (artifacts, type) => {
  if (artifacts) {
    let parsedArtifacts = null;
    try {
      parsedArtifacts = JSON.parse(artifacts);
    } catch (e) {
      parsedArtifacts = artifacts;
    }

    if (parsedArtifacts && Array.isArray(parsedArtifacts)) {
      const typedArtifacts = parsedArtifacts
        .filter((artifact) => artifact.type === type)
        .map((artifact) => ({
          title: artifact.display_name,
          artifact_id: artifact.artifact_id,
          url:
            type === "offer"
              ? "/triple-rolling-offer"
              : `/artifacts/${artifact.artifact_id}`,
          use_flux: artifact?.use_flux,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)); // Sorting step
      return typedArtifacts;
    }
  }
  return [];
};
